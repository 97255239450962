import './Home.css';
import FishGrid from '../components/FishGrid.js';

function Home() {

  return (
    <div className="Home">
      <FishGrid />
    </div>
  );
}

export default Home;
